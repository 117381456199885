import { LOGIN_RESPONSE, LOGGED, VERIFY_PIN_RESPONSE, SMS_LOGIN_RESPONSE } from './login.types'

/**
 * @fileOverview Manages the response of action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  status: false,
  logged: false,
  loginResponse: {},
  patientTicker: {},
  pinResponse: {},
  smsLoginResponse: {},
}

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESPONSE: {
      return { ...state, ...{ loginResponse: action.payload } }
    }
    case LOGGED: {
      return { ...state, ...{ logged: action.payload } }
    }
    case VERIFY_PIN_RESPONSE: {
      return { ...state, ...{ pinResponse: action.payload } }
    }
    case SMS_LOGIN_RESPONSE: {
      return { ...state, ...{ smsLoginResponse: action.payload } }
    }
    default:
      return state
  }
}
export default loginReducer
