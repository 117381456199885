import {
  PAYMENT_RESPONSE,
  PAYMENT_DETAILS_RESPONSE,
  SUCCESS_PAYMENT,
  UPDATE_MINUTES,
} from './payment.types'
const INITIAL_STATE = {
  payDetails: {},
  paymentDetails: {},
  successValue: true,
  minutes: 0,
}

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_RESPONSE: {
      return { ...state, ...{ payDetails: action.payload } }
    }
    case PAYMENT_DETAILS_RESPONSE: {
      return { ...state, ...{ paymentDetails: action.payload } }
    }
    case SUCCESS_PAYMENT: {
      return { ...state, ...{ successValue: action.payload } }
    }
    case UPDATE_MINUTES: {
      return { ...state, ...{ minutes: state.minutes + action.payload } }
    }
    default:
      return state
  }
}
export default paymentReducer
