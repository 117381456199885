import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
// import { BASE_URL } from 'Utils/baseUrl'
import {
  CREATE_PAYMENT,
  REQUEST_CANCEL,
  PAYMENT_DETAILS,
} from './payment.types'
import { paymentResponse, paymentDetailsResponse } from './payment.actions'
import environment from 'environments/environment'
const baseUrl = environment.baseUrl
const PaymentEpic = {}

PaymentEpic.makePaymentApi = (action$) =>
  action$.pipe(
    ofType(CREATE_PAYMENT),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 8,
        },
        url: `${baseUrl}/trillium-patient-service/v1/payment/stripe/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => paymentResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

PaymentEpic.getPaymentDetailsApi = (action$) =>
  action$.pipe(
    ofType(PAYMENT_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/trillium-patient-service/v1/payment/patient/info/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => paymentDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default PaymentEpic
