import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import {updateMinutes } from 'Redux/Payment/payment.actions'
import PropTypes from 'prop-types'
import { NotificationContainer } from 'react-notifications'
import { Toaster } from 'react-hot-toast'
import toast from 'react-hot-toast'
import 'react-notifications/lib/notifications.css'
import './App.css'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from './Utils/private-route'
const Login = React.lazy(() => import('./Containers/Login'))
const Auth = React.lazy(() => import('./Containers/Auth'))
const Payment = React.lazy(() => import('./Containers/Payment'))
const SuccessPage = React.lazy(() => import('./Components/SuccessPage'))

function App() {
  useEffect(()=>{
if(window.location.href.split('/').pop()!=='login'){
  setInterval(() => {
let m= localStorage.getItem('minute')
let p=parseInt(m)
if(p===15||p>15){
  toast.error('The connection has timed out. Please Try Again!')
  setTimeout(() => {
    window.location.href = '/login'
  }, 3000)
}else{
  let a= localStorage.getItem('minute')
  let sum = parseInt(a)+1
  localStorage.setItem('minute',sum)
}
  }, 60000)
}
  },[])
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={''}>
          <Switch>
            <PrivateRoute
              permission={true}
              path="/payment"
              component={Payment}
            />
            <PrivateRoute
              permission={true}
              path="/payment-success"
              component={SuccessPage}
            />
            <Route path="/login" component={Login} />
            <Route path="/sms" component={Auth} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <Toaster />
      <NotificationContainer />
    </>
  )
}

const mapStateToProps = (state) => ({
  LoginData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  updateMinutes: (values) => dispatch(updateMinutes(values)),
})
App.propTypes = {
  updateMinutes: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
