import { combineEpics } from 'redux-observable'
import loginEpic from './Login/login.epic'
import PaymentEpic from './Payment/payment.epic'

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  loginEpic.login,
  loginEpic.logOut,
  loginEpic.verifyPin,
  loginEpic.smsLogin,
  PaymentEpic.makePaymentApi,
  PaymentEpic.getPaymentDetailsApi,
)
export default rootEpic
